/**
 * Created by gantushig on 4/16/17.
 */

import React, {useEffect, useReducer} from "react";
import DatePicker from "react-datepicker";
import {EndpointsExpress, TOKEN} from "./Constants";
import history from "./history";

import ClipLoader from "react-spinners/ClipLoader";
import {css} from "@emotion/react";
import {toast} from "react-toastify";
import   axios from './axiosConfig'


const initialState = {
    loading:false,
    file:{},
    issued:new Date()
};


const reducer = (state, action) => {
    switch (action.type) {

        case 'UPDATE_SUCCESS':
            return {
                ...state,
                file:action.file,
                issued:Date.parse(action.file.issued),
                loading: false
            }

        case 'LOADING':
            return {
                ...state,
                loading: true
            }
        case 'LOADING_STOP':
            return {
                ...state,
                loading: false
            }
        case 'CHANGE_ISSUED':
            return {
                ...state,
                issued: action.date
            }
        case 'FETCH_SUCCESS':
            return {
                ...state,
                file: action.file,
                issued:Date.parse(action.file.issued),
                loading: false
            }
        default:
            throw new Error();
    }
};


const fetchFileSuccess =(file) => {
    return {
        type: 'FETCH_SUCCESS',
        file
    }
};

const fetchFile = (dispatch, token, id) => {
    dispatch(loadingStart());
    let header = {};
    header["Authorization"] = token;
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.PRODUCT8_FILE}/${id}`;

    return axios.instance(config)
        .then(response => {

            dispatch(fetchFileSuccess(response.data.file))
        })
        .catch(error => {
            dispatch(loadingStop());
                throw(error);
        });
}

const loadingStart =() => {
    return {
        type: 'LOADING'
    }
};

const changeIssued =(date) => {
    return {
        type: 'CHANGE_ISSUED',
        date:date
    }
};

const loadingStop =() => {
    return {
        type: 'LOADING_STOP'
    }
};


const updateFileSuccess =(file) => {
    return {
        type: 'UPDATE_SUCCESS',
        file:file
    }
};

const updateFile = (dispatch, token, id, info) => {
    return new Promise(function (resolve, reject) {
        dispatch(loadingStart())

        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.PRODUCT8_FILE}/${id}`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        axios.instance(config)
            .then(response => {

                dispatch(updateFileSuccess(response.data.file))
                resolve(response);
            })
            .catch(error => {
                dispatch(loadingStop());
                reject(error);
                //throw(error);
            });
    });
}

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Product8FileEdit = ({
                         match: {params: { fileId}}
}) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const {issued, loading} = state;
    let token = localStorage.getItem(TOKEN) || null;

    useEffect(() => {
        console.log('useEffect called');
        fetchFile(dispatch, token, fileId);
    }, []);

    const handleChangeIssued = (date) => {
        dispatch(changeIssued(date));
    };

    const handleSave = () => {
        console.log('send');
        // let iss  = null
        //
        // if (issued !== null ) {
        //     // const d = state.issued.toISOString().replace('.000Z', '');
        //     // const g = new Date(d)
        //     iss =  issued.getTime()
        // }
        let dto = {
            issued:issued,
        };

        updateFile(dispatch, token, fileId, dto).then(response => {
                    toast.success("Амжилттай!");
                    history.push(`/company/${response.data.file.company_id}/product8-files`)
                }
            ).catch(error => {
            alert(error)
        });
    };
    const handleCancel = () => {
        history.goBack()
    };

    return (
        <div style={{
            background: 'white',
            paddingLeft: '20px',
            paddingTop: '20px',
            paddingRight: '20px',
            paddingBottom: "20px"
        }}>

            <div className="level">
                <div className="level-left" style={{paddingLeft: '10px', paddingTop: '10px'}}>
                    <h1 className="title is-size-4  has-text-info">Мэдээлэл оруулах</h1>
                </div>
            </div>
            <hr/>

            <div style={{background: 'white'}}>

                <div className="field">
                    <label className="label">Олгогдсон огноо</label>
                    <div className="control has-icons-left">
                        <DatePicker utcOffset={0} className="input" selected={state.issued}
                                    onChange={handleChangeIssued} placeholderText="Олгогдсон огноо оруулах"/>
                    </div>
                </div>
            </div>


            <div class="buttons is-right" style={{paddingTop: '10px'}}>
                <button className="button" type="primary" onClick={() => handleCancel()}>Буцах</button>
                <button className="button is-success" type="primary"
                        onClick={(e) => handleSave(e)}>
                    Хадгалах
                </button>
            </div>
            <ClipLoader color="#ffffff" loading={loading} css={override} size={150} />
        </div>
    )
}



export default Product8FileEdit;