/**
 * Created by gantushig on 4/16/17.
 */
import React, {useEffect, useReducer} from "react";
import CompanyEditComponent from './CompanyEditComponent';

import history from "./history";
import dayjs from "dayjs"
import {toast} from "react-toastify";
import {ActionTypes, EndpointsExpress, TOKEN} from "./Constants";
import {css} from "@emotion/react";
import axios from "./axiosConfig";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const initialState = {
    company: {},
    loading: false,
    saved:false
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.GET_COMPANY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ActionTypes.GET_COMPANY_SUCCESS: {
            let c = {...action.company}
            if ('null' != c.joined) {
                c.joined = Date.parse(c.joined); //"2019-06-12T10:20:30Z"); //// dayjs("2019-06-12 00:00:00"); c.issued);
            }
            if ('null' != c.established) {
                c.established = Date.parse(c.established); //"2019-06-29T01:00:00Z");//c.established);
            }
            return {
                ...state,
                company: c,
                loading: false
            }
        }
        case ActionTypes.UPDATE_COMPANY_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case ActionTypes.UPDATE_COMPANY_SUCCESS: {
            let c = {...action.company}
            if ('null' != c.joined) {
                c.joined = Date.parse(c.joined); //"2019-06-12T10:20:30Z"); //// dayjs("2019-06-12 00:00:00"); c.issued);
            }
            if ('null' != c.established) {
                c.established = Date.parse(c.established); //"2019-06-29T01:00:00Z");//c.established);
            }
            return {
                ...state,
                company: c,
                loading: false,
                saved:true
            }
        }

        case 'SET_COMPANY': {
            let c = {...action.company}
            return {
                ...state,
                company: c
            }
        }

        case 'CHANGE_FIELD':
            let c = {...state.company};
            c[action.name] = action.value
            if (action.name === "sector") {
                c[action.name] = Number(action.value);
            }
            return {
                ...state,
                company: c,
                loading: false
            }
        default:
            return state;
    }
};

const updateCompanyRequest = () => {
    return {
        type: ActionTypes.UPDATE_COMPANY_REQUEST
    }
};
const updateCompanySuccess = (company) => {
    return {
        type: ActionTypes.UPDATE_COMPANY_SUCCESS,
        company
    }
};

const updateCompany = (dispatch, token, companyId, info) => {

    return new Promise(function (resolve, reject) {

        dispatch(updateCompanyRequest())

        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.COMPANY_ADMIN}/${companyId}`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        axios.instance(config)
            .then(response => {
                dispatch(updateCompanySuccess(response.data.company))
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
};

const changeField = (name, value) => {
    return {
        type: 'CHANGE_FIELD',
        name,
        value
    }
}

const setCompany = (company) => {
    return {
        type: 'SET_COMPANY',
        company
    }
};

const CompanyEdit = ({inCompany, onUpdate}) => {
    const token = localStorage.getItem(TOKEN) || null;
    const [state, dispatch] = useReducer(reducer, initialState);
    const {company, loading} = state;

    useEffect(() => {
        dispatch(setCompany(inCompany));
    }, []);

    const handleChangeCheckField = (e) => {
        dispatch(changeField(e.target.name, e.target.checked))
    }
    const handleChangeField = (e) => {
        dispatch(changeField(e.target.name, e.target.value))
    }

    const handleChangeJoined = (date) => {
        dispatch(changeField("joined", date))

    };
    const handleChangeEstablished = (date) => {
        dispatch(changeField("established", date))
    };


    const handleSave = () => {

        let established_str = null;
        if (company.established !== null) {
            established_str = dayjs(company.established).format('YYYY-MM-DD');
        } else {
            established_str = "";
        }

        let joined_str = null;
        if (company.joined !== null) {
            joined_str = dayjs(company.joined).format('YYYY-MM-DD');
        } else {
            joined_str = "";
        }

        let dto = {
            name: company.name,
            name_english: company.name_english,
            state_reg_number: company.state_reg_number,
            registration_number: company.registration_number,
            established: company.established,
            soum: company.soum,
            joined: company.joined,
            district: company.district,
            aimak: company.aimak,
            address: company.address,
            phone: company.phone,
            postal_address: company.postal_address,
            email: company.email,
            social: company.social,
            web: company.web,
            is_gepir: company.is_gepir,
            is_certificate: company.is_certificate,
            sector: company.sector,
            zipcode: company.zipcode,
            is_exported: company.is_exported,
            is_exported_countries: company.is_exported_countries,
            exp_countries:company.exp_countries,
            postal_code:company.postal_code,
            established_str,
            joined_str
        };

        updateCompany(dispatch, token, company.id, dto).then(response => {
                toast.success("Амжилттай !");
                onUpdate();
                history.push(`/company/${company.id}`);
            }
        ).catch(error => {
            alert(error)
        });
    };
    const handleCancel = () => {
        console.log('cancel');
        history.goBack();
    };

    return (
        <>
            <div className="level">
                <div className="level-left" style={{paddingLeft: '10px', paddingTop: '10px'}}>
                    <h1 className="title is-size-4  has-text-info">Мэдээлэл оруулах</h1>
                </div>
            </div>
            <hr/>

            <CompanyEditComponent company={company} isCreate={false}
                                  onChangeEstablished={handleChangeEstablished}
                                  onChangeJoined={handleChangeJoined}
                                  onChangeField={handleChangeField}
                                  onChangeCheckField={handleChangeCheckField}
                                  onSave={handleSave} onCancel={handleCancel}/>
        </>
    )

}


export default CompanyEdit;