import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {fetchAllProduct8s} from "./actions/actions";
import ReactPaginate from "react-paginate";
import qs from "query-string";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import "react-datepicker/dist/react-datepicker.css";
import {Page, TOKEN} from "./Constants";
import history from "./history";

dayjs.extend(dayjsPluginUTC)

const changeOrder = (field) => {
    return {
        type: 'CHANGE_ORDER',
        field
    }
};

const Product8sPage = ({
                           location: {search}
                       }) => {
    const [name, setName] = useState(null);
    const [barcode, setBarcode] = useState(null);

    const dispatch = useDispatch();
    let token = localStorage.getItem(TOKEN) || null;

    let parsed = qs.parse(search);
    let page = parsed[Page.PAGE];
    let pagesize = parsed[Page.PAGESIZE];
    let orderField = parsed[Page.ORDER];
    let isAsc = parsed[Page.ASC];
    let qname = parsed["n"];
    let qbarcode = parsed["b"];

    if (page === undefined) {
        page = "0";
    }
    if (pagesize === undefined) {
        pagesize = "15";
    }
    if (orderField === undefined) {
        orderField = "id";
    }
    if (isAsc === undefined) {
        isAsc = "true";
    }
    if (qname === undefined) {
        qname = null;
    }
    if (qbarcode === undefined) {
        qbarcode = null;
    }

    useEffect(() => {
        console.log('useEffect called');

        dispatch(fetchAllProduct8s(
            token,
            qname,
            qbarcode,
            page, pagesize, orderField, isAsc));
    }, [qname, qbarcode, page, pagesize, orderField, isAsc]);


    const stateProducts = useSelector(state => state.product8s);
    let meta = stateProducts.meta;
    if (meta === null) {
        meta = {};
        meta.totalPages = 0;
    }
    const products = stateProducts.products;
    // let counter = 0;
    // for (let key in products) {
    //     let item = products[key];
    //     counter++;
    //     item.counter = Number(page) * Number(pagesize) + counter;
    // }

    const handleChangeName = (e) => {
        setName(e.target.value);
    };

    const handleChangeBarcode = (e) => {
        setBarcode(e.target.value);
    };


    const handlePageClick = (data) => {
        let parsed = qs.parse(search);
        parsed[Page.PAGE] = data.selected;

        history.push("?" + qs.stringify(parsed))
    };

    const handleOrderClick = (f) => {
        let lorderField = orderField;
        let lisAsc = isAsc;

        if (f === lorderField) {
            if (lisAsc === 'true') {
                lisAsc = 'false';
            } else {
                lisAsc = 'true';
            }
        } else {
            lorderField = f;
            lisAsc = true;
        }

        let parsed = qs.parse(search);
        parsed[Page.ORDER] = lorderField;
        parsed[Page.ASC] = lisAsc;
        history.push("?" + qs.stringify(parsed))
    }

    const handleSearch = (e) => {
        e.preventDefault();
        let parsed = qs.parse(search);
        parsed["n"] = name;
        parsed["b"] = barcode;
        parsed[Page.PAGE] = "0";
        history.push("?" + qs.stringify(parsed))
    };

    const handleReset = (e) => {
        e.preventDefault();
        //dispatch(loadProductsResetPageAndOrder());
    };

    let counter1 = Number(page) * Number(pagesize)
    const appts2 = products.map(p => {
        counter1++;
        return (
            <tr key={p.id}>

                <td>
                    <Link to={`/product8/${p.id}`}>
                        {counter1}
                    </Link>
                </td>
                <td>
                    <Link to={`/company/${p.company_id}/product8s`}>
                        {p.company_name}
                    </Link>
                </td>
                <td>
                    <Link to={`/product8/${p.id}`}>
                        {p.name}
                    </Link>
                </td>
                <td>{p.barcode}</td>
                <td>{p.packaging}</td>
            </tr>
        )
    });

    return (

        <div className="inner-container">

            <div className="level">
                <div className="level-left" style={{paddingTop: '10px'}}>
                    <Link to={`/product8s/create`} className="button ">
                        GS1-8 Үүсгэх
                    </Link>
                </div>
            </div>

            <ProductsSearchPanel
                name={name} barcode={barcode}
                onChangeName={handleChangeName}
                onChangeBarcode={handleChangeBarcode}
                onClickSearch={handleSearch}
            />

            <br/>
            <div>
                <h5 style={{marginBottom: "10px"}}><strong>Нийт: {meta.totalElements}</strong>
                </h5>

                <nav className="pagination">
                    <ReactPaginate previousLabel={"Өмнөх"}
                                   nextLabel={"Дараах"}
                                   initialPage={Number(page)}
                                   breakLabel={"..."}
                                   breakClassName={"pagination-ellipsis"}
                                   pageCount={meta.totalPages}
                                   marginPagesDisplayed={2}
                                   pageRangeDisplayed={5}
                                   activeLinkClassName={"is-current"}
                                   pageLinkClassName={"pagination-link"}
                                   onPageChange={(e) => handlePageClick(e)}
                                   disableInitialCallback={true}
                                   previousClassName={"pagination-previous"}
                                   nextClassName={"pagination-next"}
                                   containerClassName={"pagination-list"}
                                   forcePage={Number(page)}

                    />
                </nav>


            </div>
            <br/>
            <table className="table is-striped is-fullwidth">
                <thead>
                <tr>
                    <th><a onClick={() => handleOrderClick("id")}>#</a></th>

                    <th>Компани</th>
                    <th>Нэр</th>
                    <th><a onClick={() => handleOrderClick("barcode")}>Зур. код</a></th>
                    <th>Савлалтын хэмжээ /нэгж, багц/</th>
                </tr>
                </thead>
                <tbody>
                {appts2}
                </tbody>
            </table>

            <nav className="pagination">
                <ReactPaginate previousLabel={"Өмнөх"}
                               nextLabel={"Дараах"}
                               initialPage={Number(page)}
                               breakLabel={"..."}
                               breakClassName={"pagination-ellipsis"}
                               pageCount={meta.totalPages}
                               marginPagesDisplayed={2}
                               pageRangeDisplayed={5}
                               activeLinkClassName={"is-current"}
                               pageLinkClassName={"pagination-link"}
                               onPageChange={(e) => handlePageClick(e)}
                               previousClassName={"pagination-previous"}
                               nextClassName={"pagination-next"}
                               disableInitialCallback={true}
                               containerClassName={"pagination-list"}
                               forcePage={Number(page)}
                />
            </nav>
            <br/><br/>
        </div>
    )

}


const ProductsSearchPanel = ({
                                 name, barcode,
                                 onChangeName,
                                 onChangeBarcode,
                                 onClickSearch
                             }) => {

    return (
        <div>


            <div className="field">
                <label className="label">Нэр</label>
                <div className="field-body ">
                    <div className="control ">
                        <input className="input" type="text" value={name} style={{width: '400px'}}
                               onChange={onChangeName} placeholder="Оруулах"/>
                    </div>
                </div>
            </div>

            <div className="field">
                <label className="label">Зураасан код</label>
                <div className="field-body ">
                    <div className="control ">
                        <input className="input" type="text" value={barcode} style={{width: '400px'}}
                               onChange={onChangeBarcode} placeholder="Оруулах"/>
                    </div>
                </div>
            </div>


            <div className="field is-horizontal">
                <div className="field-label">

                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control buttons">


                            <button onClick={onClickSearch} className="button bg-orange">
                                <span style={{color: "white"}}>Хайх</span>
                            </button>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Product8sPage

