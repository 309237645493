/**
 * Created by gantushig on 11/6/15.
 */

import {Link} from "react-router-dom";
import React, {useEffect, useReducer, useState} from "react";
import {EndpointsExpress, TOKEN} from "./Constants";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import classnames from "classnames";
import history from "./history";
import   axios from './axiosConfig'

dayjs.extend(dayjsPluginUTC)


const initialState = {
    product: {},
    loading: false,
    fileCount:0
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_PRODUCT_REQUEST':
            return {
                ...state,
                loading: true
            }
        case 'FETCH_PRODUCT_SUCCESS':
            return {
                ...state,
                product: action.product,
                loading: false
            }
        case 'CHECK_PRODUCT_REQUEST':
            return {
                ...state,
                loading: true
            }
        case 'CHECK_PRODUCT_SUCCESS':
            return {
                ...state,
                fileCount: action.count,
                loading: false
            }

        case 'REMOVE_SUCCESS':
            return {
                ...state,
                product: action.product,
                loading: false
            }

        case 'DOWNLOAD_SUCCESS':
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
};


const fetchProduct8Request = () => {
    return {
        type: 'FETCH_PRODUCT_REQUEST'
    }
};

const fetchProduct8Success = (status, product) => {
    return {
        type: 'FETCH_PRODUCT_SUCCESS',
        status,
        product
    }
};

const fetchProduct8 = (dispatch, token, productId) => {

    dispatch(fetchProduct8Request())
    let header = {};
    header["Authorization"] = token;
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${EndpointsExpress.PRODUCT8}/${productId}`;

    return axios.instance(config)
        .then(response => {

            dispatch(fetchProduct8Success(response.data.status, response.data.product))
        })
        .catch(error => {

            throw(error);

        });
}



const checkProduct8Request = () => {
    return {
        type: 'CHECK_PRODUCT_REQUEST'
    }
};

const checkProduct8Success = (status, product) => {
    return {
        type: 'CHECK_PRODUCT_SUCCESS',
        status,
        product
    }
};

const checkProduct8 = ( dispatch, token, productId) => {

    return new Promise(function (resolve, reject) {
        dispatch(checkProduct8Request())
        let header = {};
        header["Authorization"] = token;
        header["Accept"] = "application/json";
        header["Content-Type"] = "application/json";

        let config = {};
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.PRODUCT8}/${productId}/check`;

        axios.instance(config)
            .then(response => {
                dispatch(checkProduct8Success(response.data.status, response.data.count))
                resolve(response)
                // if (response.data.count === 0) {
                //     remove(dispatch, token, productId)
                // } else {
                //     dispatch(checkProduct8Success(response.data.status, response.data.count))
                // }

            })
            .catch(error => {
                reject(error);
                throw(error);

            });
    });
}


const removeRequest = () => {
    return {
        type: 'REMOVE_REQUEST'
    }
};

const removeSuccess = (product) => {
    return {
        type: 'REMOVE_SUCCESS',
        product
    }
};
const remove = (dispatch, token, id) => {

    return new Promise(function (resolve, reject) {

        dispatch(removeRequest())
        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${EndpointsExpress.PRODUCT8}/${id}`;
        config["method"] = "delete";

        axios.instance(config)
            .then(response => {

//            fetchProduct8Files(dispatch, token, productId, 0, 15)
                dispatch(removeSuccess(response.data.product))
                resolve(response)
            })
            .catch(error => {
                reject(error);
                //throw(error);

            });
    });
};




const Product8View = ({
                          match: {params: {productId}}

                      }) => {

    const token = localStorage.getItem(TOKEN) || null;
    const [state, dispatch] = useReducer(reducer, initialState);
    const {product} = state;
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    let deleteModalClass = classnames({
        modal: true,
        'is-active': openDeleteModal
    });

    const handleDelete = (id) => {
        console.log('delete');
        setOpenDeleteModal(true)
    };

    const handleSaveDeleteModal = () => {
        setOpenDeleteModal(false)
        checkProduct8( dispatch, token, productId).then(response => {
                if ( response.data.count > 0 ) {
                    alert("Файлуудаа устгасны дараа устгах боломжтой!")
                } else {
                    remove(dispatch, token, productId).then(resp => {
                        alert("Амжилттай устгалаа!")
                        history.push("/product8s")
                    });
                }
            }
        ).catch(error => {
            alert(error)
        })
    }
    const handleCancelDeleteModal = () => {
        setOpenDeleteModal(false)
    }

    useEffect(() => {
        console.log('useEffect called');
        fetchProduct8(dispatch, token, productId)
    }, []);

    if (state.product === null) {
        return (
            <div>
                No Product
            </div>
        )
    }


    return (
        <div className="inner-container">
            <div className={deleteModalClass}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Устгах?</p>
                        <button className="delete" aria-label="close"></button>
                    </header>
                    <section className="modal-card-body">
                        Та устгахдаа итгэлтэй байна уу?
                    </section>
                    <footer className="modal-card-foot">
                        <button className="button is-danger" onClick={handleSaveDeleteModal}>Тийм</button>
                        <button className="button" onClick={handleCancelDeleteModal}>Үгүй</button>
                    </footer>
                </div>
            </div>

            <div className="buttons has-addons is-right">
                <Link to={`/product8/${productId}/edit`} className="button">
                    Өөрчлөх
                </Link>
                <a className="button" onClick={()=>handleDelete()}>
                    Устгах
                </a>
            </div>

            <h2>Бүтээгдэхүүний мэдээлэл</h2>
            <br/>
            <table className="table table-bordered is-bordered is-fullwidth">
                <tbody>
                <tr>
                    <th className="company-title">Компани</th>
                    <td className="company-value">{product && product.company_name}</td>
                </tr>
                <tr>
                    <th className="company-title">Зураасан код</th>
                    <td className="company-value">{product && state.product.barcode}</td>
                </tr>
                <tr>
                    <th>Нэр</th>
                    <td>{product && product.name}</td>
                </tr>
                <tr>
                    <th>Савлалтын хэмжээ</th>
                    <td>{product && product.packaging}</td>
                </tr>
                <tr>
                    <th>Орц найрлага</th>
                    <td>{product && product.constituent}</td>
                </tr>
                <tr>
                    <th>Шинж чанар</th>
                    <td>{product && product.characteristics}</td>
                </tr>
                <tr>
                    <th>Зориулалт</th>
                    <td>{product && product.handling}</td>
                </tr>
                <tr>
                    <th>Хэрэглэх заавар</th>
                    <td>{product && product.instruction}</td>
                </tr>
                <tr>
                    <th>Хадгалах нөхцөл</th>
                    <td>{product && product.storage}</td>
                </tr>

                </tbody>
            </table>

            {/*<hr/>*/}
            {/*<Product8ViewFiles productId = {productId} />*/}

        </div>
    )
}

export default Product8View;

